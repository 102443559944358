<template>
  <div :style="{backgroundImage: 'url(' + poll.bannerUrl + ')'}" class="center-poll-trip" id="poll">
    <PollLanguage @changeLanguagePoll="changeLanguagePoll" :languagePoll="languagePoll" v-if="poll.languageAvailable && poll.languageAvailable === 1" />
    <div class="header_poll" v-if="fallo == '' && !msg">
      <div :style="{backgroundImage: 'url(' + poll.logoUrl + ')'}" class="poll_logo"></div>
    </div>
    <div class="cont_curves" v-if="fallo == '' && !msg">
      <div class="curve_one"></div>
      <div class="curve_two"></div>
    </div>
    <div class="content_poll" v-if="fallo == '' && !msg">
      <div class="cont_title">
        <span class="title_poll ffr fw500" v-html="languagePoll === 'es' ? poll.title : poll.titleEnglish"></span>
      </div>
      <div class="cont_questions">
        <div class="cont_description">
          <span class="ffr" v-html="languagePoll === 'es' ? poll.description : poll.descriptionEnglish"></span>
        </div>
        <div v-if="calification.length > 0">
          <div class="columns is-multiline mx-0 main_stars_poll">
            <div
              :key="preg.index"
              class="column is-4 is-4-mobile stars_poll"
              v-for="(preg) in starQuestions"
            >
              <div :id="'qp' + preg.index" :tabindex="preg.index" class="cont_stars">
                <span
                  :class="{'error_answer' : fails[preg.index] && calification[preg.index]['value'] == 0}"
                  class="question ffr fw500"
                >{{preg.question}}*</span>
                <Stars :questions="preg" v-model="calification[preg.index]['value']"></Stars>
              </div>
            </div>
          </div>
          <div class="columns is-multiline mx-0 main_questions">
            <div
              :class="{'first' : !(ind % 2)}"
              :id="'qp' + preg.index"
              :key="preg.index"
              :tabindex="preg.index"
              class="column is-6 questions_poll"
              v-for="(preg, ind) in otherQuestions"
            >
              <div v-if="preg.type === 'checkbox'">
                <span
                  :class="{'error_answer' : fails[preg.index] && (calification[preg.index]['value'] == 0 || calification[preg.index]['value'] == '' || calification[preg.index]['value'] == null)}"
                  class="question ffr fw500"
                >{{preg.question}}*</span>
                <Check
                  :class="{'error_answer' : fails[preg.index] && (calification[preg.index]['value'] == 0 || calification[preg.index]['value'] == '' || calification[preg.index]['value'] == null)}"
                  :questions="preg"
                  v-model="calification[preg.index]['value']"
                ></Check>
              </div>
              <MultiRadio
                :languagePoll="languagePoll"
                :class="{'error_answer' : fails[preg.index] && (calification[preg.index]['value'] == 0 || calification[preg.index]['value'] == '' || calification[preg.index]['value'] == null)}"
                :questions="preg"
                v-if="preg.type === 'multiRadio'"
                v-model="calification[preg.index]['value']"
              ></MultiRadio>
              <OpenQuestion
                :languagePoll="languagePoll"
                :class="{'error_answer' : fails[preg.index] && (calification[preg.index]['value'] == 0 || calification[preg.index]['value'] == '' || calification[preg.index]['value'] == null)}"
                :questions="preg"
                v-if="preg.type === 'openQuestion'"
                v-model="calification[preg.index]['value']"
              ></OpenQuestion>
              <RadioQuestion
                :languagePoll="languagePoll"
                :class="{'error_answer' : fails[preg.index] && (calification[preg.index]['value'] == 0 || calification[preg.index]['value'] == '' || calification[preg.index]['value'] == null)}"
                :questions="preg"
                v-if="preg.type === 'radioQuestion'"
                v-model="calification[preg.index]['value']"
              ></RadioQuestion>
              <HourQuestionVue
                :languagePoll="languagePoll"
                :class="{'error_answer' : fails[preg.index] && (calification[preg.index]['value'] == 0 || calification[preg.index]['value'] == '' || calification[preg.index]['value'] == null)}"
                :questions="preg"
                v-if="preg.type === 'hourQuestion'"
                v-model="calification[preg.index]['value']"
              ></HourQuestionVue>
              <CalendarQuestionVue
                :languagePoll="languagePoll"
                :class="{'error_answer' : fails[preg.index] && (calification[preg.index]['value'] == 0 || calification[preg.index]['value'] == '' || calification[preg.index]['value'] == null)}"
                :questions="preg"
                v-if="preg.type === 'calendarQuestion'"
                v-model="calification[preg.index]['value']"
              ></CalendarQuestionVue>
              <OpenQuestionInput
                :languagePoll="languagePoll"
                :class="{'error_answer' : fails[preg.index] && (calification[preg.index]['value'] == 0 || calification[preg.index]['value'] == '' || calification[preg.index]['value'] == null)}"
                :questions="preg"
                v-if="preg.type === 'openQuestionInput'"
                v-model="calification[preg.index]['value']"
              ></OpenQuestionInput>
              <RadioQuestionButtonVue
                :languagePoll="languagePoll"
                :class="{'error_answer' : fails[preg.index] && (calification[preg.index]['value'] == 0 || calification[preg.index]['value'] == '' || calification[preg.index]['value'] == null)}"
                :questions="preg"
                v-if="preg.type === 'radioButtonQuestion'"
                v-model="calification[preg.index]['value']"
              ></RadioQuestionButtonVue>
              <YesOrNoQuestionVue
                :languagePoll="languagePoll"
                :class="{'error_answer' : fails[preg.index] && (calification[preg.index]['value'] == 0 || calification[preg.index]['value'] == '' || calification[preg.index]['value'] == null)}"
                :questions="preg"
                v-if="preg.type === 'yesOrNoQuestion'"
                v-model="calification[preg.index]['value']"
              ></YesOrNoQuestionVue>
            </div>
          </div>
        </div>
        <div class="cont_comments">
          <textarea
            :placeholder="languagePoll === 'es' ? poll.placeholder : poll.placeholderEnglish"
            class="textarea_comments ffr fw400"
            style="min-height: 100px; max-height: 100px; height: 100px;"
            v-model="comments"
          ></textarea>
        </div>
        <div class="cont_error" v-if="error != ''">
          <span class="f12">
            <i class="fa fa-exclamation-triangle"></i>
            &nbsp;&nbsp;{{ error }}
          </span>
        </div>
        <div class="cont_button">
          <button
            @click="sendAnswer()"
            class="btn_send ffr fw500"
            v-if="!loading"
          >{{ $t('pollView.text1') }}</button>
          <button class="btn_send ffr fw500" v-if="loading">{{ $t('pollView.text2') }}</button>
        </div>
        <div class="cont_query">
          <span class="ffr fw400">
            {{ $t('pollView.text3') }}
            <a
              :href="'tel:' + vendor.phone"
              class="ffr fw500"
            >{{ vendor.phone }}</a>
          </span>
          <span class="ffr fw400" v-if="vendor.email != null">
            {{ $t('pollView.text4') }}
            <a
              :href="'mailto:' + vendor.email"
              class="ffr fw500"
            >{{ vendor.email }}</a>
          </span>
        </div>
      </div>
    </div>
    <div
      class="content_poll fallo_msg center-poll-trip change-size"
      style="flex-direction: column;"
      v-if="fallo != '' || msg"
    >
      <div class="header_poll" style="padding-top: 5px; background: unset;" v-if="fallo != ''">
        <div :style="{backgroundImage: 'url(' + poll.logoUrl + ')'}" class="poll_logo"></div>
      </div>
      <div class="cont_fallo" v-if="fallo != ''">
        <span class="text_response fallo ffr fw500">{{ fallo }}</span>
        <span
          v-if="date != null"
        >{{ date | moment('timezone', vendorTimezone, 'DD [de] MMMM [de] YYYY [a las] hh:mm A')}}</span>
      </div>
      <div class="cont_msg centerAll" v-if="msg">
        <div class="header_poll" style="padding-top: 5px; background: unset;">
          <div :style="{backgroundImage: 'url(' + poll.logoUrl + ')'}" class="poll_logo"></div>
        </div>
        <span class="text_ty ffr fw500">{{ $t('pollView.text5') }}</span>
        <span class="text_response ffr fw400">{{ $t('pollView.text6') }}</span>
        <span class="text_ty ffssp fw400" v-if="showTripAd">{{ $t('pollView.text7') }}</span>
        <div class="img_external_father" v-if="showTripAd">
          <div
            @click="gotToClick('trip')"
            class="img_external"
            style="margin-right: 10px;"
            v-if="params.tripAdvisorURL"
          >
            <img
              alt="precompro.com"
              class="img_external_item_trip"
              src="@/assets/images/icons/polls/trip.svg"
            />
          </div>
          <div @click="gotToClick('google')" class="img_external" v-if="params.googleQualifyURL">
            <img
              alt="precompro.com"
              class="img_external_item_gogle"
              src="@/assets/images/icons/polls/gogle.svg"
            />
          </div>
        </div>
      </div>
      <div :style="{'margin-top': (fallo != '') ? '50px' : '20px'}" class="showInfoPoll">
        <span class="showInfoPoll-Item">
          Si tienes alguna consulta al respecto, por favor comunicarse al
          {{ vendor.phone }} ó escríbenos a {{ vendor.email }}
        </span>
      </div>
    </div>
    <div class="footer_poll">
      <img
        alt="built by precompro.com"
        class="img_footer"
        src="@/assets/images/icons/polls/logo_precompro.svg"
      />
    </div>
  </div>
</template>
<script>
import globalTypes from '@/store/types/global';
import whiteLabelTypes from '@/store/types/whiteLabel';
import { mapGetters } from 'vuex';
import Stars from '@/components/pollQuestions/stars';
import Check from '@/components/pollQuestions/checkbox';
import MultiRadio from '@/components/pollQuestions/multiRadio';
import OpenQuestion from '@/components/pollQuestions/openQuestion';
import RadioQuestion from '@/components/pollQuestions/radioQuestion';
import _ from 'lodash';
import RadioQuestionButtonVue from '../components/pollQuestions/radioQuestionButton.vue';
import OpenQuestionInput from '../components/pollQuestions/openQuestionInput.vue';
import CalendarQuestionVue from '../components/pollQuestions/calendarQuestion.vue';
import HourQuestionVue from '../components/pollQuestions/hourQuestion.vue';
import YesOrNoQuestionVue from '../components/pollQuestions/yesOrNoQuestion.vue';
import PollLanguage from '@/components/pollQuestions/pollLanguage.vue';

export default {
  name: 'Poll',
  components: { Stars, YesOrNoQuestionVue, Check, MultiRadio, OpenQuestion, RadioQuestion, RadioQuestionButtonVue, OpenQuestionInput, CalendarQuestionVue, HourQuestionVue, PollLanguage },
  data () {
    return {
      comments: '',
      calification: [],
      loading: false,
      msg: false,
      error: '',
      fallo: '',
      date: null,
      showTripAd: false,
      starsCount: 0,
      totalStars: 0,
      ponderadoStars: 0,
      starQuestions: [],
      otherQuestions: [],
      fails: [],
      firstFail: null,
      languagePoll: ''
    };
  },
  computed: {
    ...mapGetters({
      integrations: [whiteLabelTypes.getters.integrations],
      integrationDefaults: [whiteLabelTypes.getters.integrationDefaults]
    })
  },
  created () {
    this.loadScript(this.integrations);
  },
  mounted () {
    let subdomain = this.subDomain();
    const vendorUrl = subdomain;
    let params = this.$route.query;
    let isQuery = Object.keys(params).length;
    if (isQuery > 0) {
      this.$store.commit(globalTypes.mutations.setVendorUrl, vendorUrl);
      this.$store.dispatch({ type: 'whiteLabel:vendorId', data: { url: vendorUrl } })
        .then(response => {
          if (response.data.vendorId) {
            this.$store
              .dispatch({
                type: 'whiteLabel:vendor',
                data: { vendorId: response.data.vendorId }
              })
              .then(data => {
                document.title = data.data.vendor.displayName
                  ? data.data.vendor.displayName + ' - '
                  : '';
                document.querySelector("link[rel*='icon']").href =
                  (data.data.vendor.photoURL !== null) ? data.data.vendor.photoURL : data.data.vendor.thumbnailURL;
              });
          }
        });
      this.$store.dispatch({ type: 'poll:encuesta', data: { info: params.data } })
        .then(response => {
          if (response.data.code !== 200) {
            if (response.data.code === 403) {
              this.date = response.data.date;
              this.fallo = response.data.error;
              this.poll.bannerUrl = response.data.bannerUrl;
              this.poll.logoUrl = response.data.logoUrl;
            } else {
              this.fallo = 'Información de encuesta no encontrada!';
            }
          } else {
            if (
              Object.keys(this.poll).length > 0 &&
              this.calification.length === 0
            ) {
              let qualify = [];
              _.forEach(this.poll.preguntas, function (item) {
                let i = {
                  type: item.question,
                  sub_option: item.sub_option,
                  id: item.id,
                  value: 0
                };
                qualify.push(i);
              });
              this.calification = qualify;
            }
            var inThis = this;
            _.forEach(this.poll.preguntas, function (item) {
              if (item.type === 'stars') {
                inThis.starsCount++;
              }
            });
          }
        })
        .catch(fallo => {
          if (fallo.request.status === 500) {
            this.fallo = fallo.request.statusText;
          } else {
            this.fallo = 'Información de encuesta no encontrada!';
          }
        });
    } else {
      this.fallo = 'Información de encuesta no encontrada!';
    }
    this.$store.commit(whiteLabelTypes.mutations.setLoader, false);
  },
  methods: {
    gotToClick (provider) {
      switch (provider) {
        case 'google':
          window.open(this.params.googleQualifyURL);
          break;
        case 'trip':
          window.open(this.params.tripAdvisorURL);
          break;
      }
    },
    sendAnswer () {
      this.totalStars = 0;
      let error = 0;
      this.firstFail = null;
      _.map(this.calification, (calification, i) => {
        if (calification.value === 0 || calification.value === '' || calification.value === null) {
          error = 1;
          this.fails[i] = true;
          if (this.firstFail === null) {
            this.firstFail = i;
          }
        } else {
          this.fails[i] = false;
        }
      });
      const copyCalification = [...this.calification];
      this.calification = copyCalification;
      if (this.firstFail !== null) {
        document.getElementById('qp' + this.firstFail).focus();
      }
      if (error) {
        this.error = 'Todos los campos con (*) son obligatorios.';
      } else {
        this.loading = true;
        let data = this.$route.query;
        var inThis = this;
        _.forEach(this.calification, function (item) {
          if (item.typeQuestion === 'stars') {
            inThis.totalStars += parseInt(item.value);
          }
        });
        const newCalifications = this.calification.map((calification) => {
          if (typeof calification.value === 'object' && !Array.isArray(calification.value)) {
            calification.extraValue = calification.value.extraValue;
            calification.value = calification.value.option;
          }
          return calification;
        });
        this.$store
          .dispatch({
            type: 'poll:sendAnswer',
            data: {
              comments: this.comments,
              calification: newCalifications,
              id: data.data
            }
          })
          .then(data => {
            if (data.data.code === 200) {
              this.ponderadoStars = this.totalStars / this.starsCount;
              if (this.ponderadoStars >= 4 && this.params.activeTripAdvisor) {
                this.showTripAd = true;
              }
              this.msg = true;
              this.loading = false;
            } else {
              this.loading = false;
              this.error = data.data.error;
            }
          })
          .catch(() => {
            this.loading = false;
            this.error =
              'No se pudo guardar sus respuestas, intentelo de nuevo por favor.';
          });
        this.loading = false;
      }
    },
    changeLanguagePoll () {
      this.languagePoll = this.languagePoll === 'es' ? 'en' : 'es';
    }
  },
  watch: {
    poll () {
      if (this.poll.languageAvailable !== undefined && this.poll.languageAvailable === 0) {
        this.languagePoll = 'es';
      } else {
        this.languagePoll = window.localStorage.getItem('lang') ? window.localStorage.getItem('lang') : 'es';
      }
      if (Object.keys(this.poll).length > 0 && this.calification.length === 0) {
        let data = [];
        _.forEach(this.poll.preguntas, function (item) {
          let i = {
            type: item.question,
            id: item.id,
            typeQuestion: item.type,
            value: 0
          };
          data.push(i);
        });
        this.poll.preguntas.forEach((element, i) => {
          element.index = i;
          if (element.type === 'stars') {
            this.starQuestions.push(element);
          } else {
            this.otherQuestions.push(element);
          }
        });
        this.calification = data;
      }
    },
    languagePoll (value) {
      this.$i18n.locale = value;
      this.$moment.locale(value);
      this.$validator.locale = value;
      window.localStorage.setItem('lang', value);
    }
  }
};
</script>
<style lang="scss">
@import "@/assets/styles/Polls/poll.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700");
</style>
